import React from 'react'
import { graphql } from 'gatsby'
import Layout from './layout'
import Seo from './seo'

const BlogPost = ({ data }) => {
  const post = data.markdownRemark

  return (
    <>
      <Seo pageTitle={post.frontmatter.title} />
      <Layout title={post.frontmatter.title}>
        <div className='container'>
          <div className='row my-5'>
            <div className='offset-lg-3 col-lg-6'>
              <h1 className='display'>{post.frontmatter.title}</h1>
              <h2 className='lead'>{post.frontmatter.date}</h2>
              <hr className='my-5' />
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query postBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 300)
      html
      frontmatter {
        title
        date(formatString: "dddd DD MMMM YYYY")
      }
      fields {
        slug
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
